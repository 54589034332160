
'use client'
import { isAuth } from '@/actions/auth';
import { Toaster } from "@/components/ui/toaster";
import { TooltipProvider } from "@/components/ui/tooltip";
import { registerServiceWorkers } from '@/hooks/useHandlerNotification';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { QueryClient as NewQueryClient, QueryClientProvider as NewQueryClientProvider } from "@tanstack/react-query";
import _ from 'lodash';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { PropsWithChildren, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { UContactModal } from "../components/settings/phone-providers/ucontact/UContactModal";
import store from "../redux/store";
import { SocketContextProvider } from './crm/socket/SocketContext';

const newQueryClient = new NewQueryClient()

export default function Providers({ children }: PropsWithChildren) {

    const hasUContactConfig = _.get(isAuth(), "phoneProviders", []).find((provider) => provider.provider === "ucontact")

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
            },
        },
    })

    useEffect(() => {
        posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY as string, {
            api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || "https://us.i.posthog.com",
            person_profiles: 'identified_only',
            capture_pageview: false,
            opt_in_site_apps: true,
            loaded: (posthog) => {
                if (process.env.NODE_ENV === "development") posthog.debug()
                const currentUser = isAuth()

                if (currentUser) {
                    const email = _.get(currentUser, "email", "")
                    const company = currentUser.group.ancestors.length > 0 ? currentUser.group.ancestors[0] : currentUser.group
                    posthog.setPersonPropertiesForFlags({ email, companyId: company._id });

                    posthog.identify(currentUser._id, {
                        phone: currentUser.phone,
                        companyId: company._id,
                        email
                    })
                }
            },
        })
    }, []);
    

    useEffect(() => {
        registerServiceWorkers()
    }, [])

    return <>
        <PostHogProvider client={posthog}>
            <NewQueryClientProvider client={newQueryClient}>
                <QueryClientProvider client={queryClient}>
                    <Provider store={store}>
                        <ThemeProvider theme={theme}>
                            <SocketContextProvider>
                                <TooltipProvider delayDuration={400}>
                                    <div id="portal-presentations"></div>
                                    <div id="portal-alerts"></div>
                                    <div id="notifications-portal"></div>
                                    {children}
                                    {hasUContactConfig && <UContactModal />}
                                </TooltipProvider>
                            </SocketContextProvider>
                            <Toaster />
                        </ThemeProvider>
                    </Provider>
                </QueryClientProvider>
            </NewQueryClientProvider>
        </PostHogProvider>
    </>
}

const theme = createTheme({
    palette: {
        primary: {
            main: "#0080ff",
        },
    },
    typography: {
        fontFamily: '"Open Sans", sans-serif, "Helvetica Neue", Arial',
        h6: {
            fontFamily: '"Plus Jakarta Sans", sans-serif, "Helvetica Neue", Arial',
            fontWeight: 800,
        },
        subtitle1: {
            fontFamily: '"Plus Jakarta Sans", sans-serif, "Helvetica Neue", Arial',
            fontWeight: 700,
        },
        title: {
            fontFamily: '"Plus Jakarta Sans", sans-serif, "Helvetica Neue", Arial',
            fontWeight: 700,
        },
        title2: {
            fontFamily: '"Plus Jakarta Sans", sans-serif, "Helvetica Neue", Arial',
        },
    },
})
