import axios from "axios"
import cookie from "js-cookie"
import fetch from "isomorphic-fetch"
import { QueryClient } from "@tanstack/react-query"
import { fetcher } from "@/helpers/fetcher"
import _ from "lodash"
import { formatSearchParams } from "@/helpers/formatSearchParams"
import { KEY_LOCAL_STORAGE } from "@/components/Header/libs/interfaces-enums"
import posthog from 'posthog-js'
const API = process.env.API
const API_NEXT = process.env.API_NEXT

export const signin = (user) => {
  return fetch(`${API_NEXT}/login`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => console.log(err))
}

export const upsertReportGrid = (config) => {
  return fetcher({
    url: "/user/config/report",
    method: "PATCH",
    body: config,
    defaultContentType: true,
  })
}

export const signin_push = (user) => {
  return fetch(`${API_NEXT}/login-push`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + (getCookie("admin_token") ?? getCookie("token")),
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => console.log(err))
}

export const register = (user) => {
  return fetch(`${API_NEXT}/register`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => console.log(err))
}

export const createUser = (user) => {
  return fetch(`${API_NEXT}/user`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => console.log(err))
}

export const update = (user) => {
  return fetch(`${API_NEXT}/register/update`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => console.log(err))
}

export const updateById = (user) => {
  return fetch(`${API_NEXT}/user/${user._id}/update`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => console.log(err))
}

export const deactivate = (user) => {
  return fetch(`${API}/register/deactivate`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => console.log(err))
}

export const deactivateUser = ({ id, ...rest }) => {
  const queryParams = formatSearchParams(rest)

  return fetcher({
    url: `/user/${id}/deactivate?${queryParams}`,
    method: "PATCH",
    defaultContentType: true,
  })
}

export const downloadUserListReport = () => {
  return axios(`${API}/groups/download/userlisthistory`, {
    method: "GET",
    responseType: "blob",
    headers: {
      Authorization: "JWT " + getCookie("token"),
    },
  })
    .then((response) => {
      return response
    })
    .catch((err) => console.log(err))
}

export const deleteOne = (id) => {
  return fetch(`${API}/user/${id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => console.log(err))
}

const deleteSession = () => {
  removeCookie("token")
  removeCookie("admin_token")
  removeCookie("app.sid")
  removeLocalStorage(KEY_LOCAL_STORAGE.USER)
  removeCookie("real_user_token")

  const queryClient = new QueryClient()
  queryClient.clear()
  if (window.Intercom) {
    window.Intercom("shutdown")
  }
}

export const signout = (next) => {
  let token = getCookie("token")
  deleteSession()
  next()
  return fetch(`${API}/signout`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + token,
    },
  })
    .then((response) => {
      posthog.reset()
      console.log("signout success")
    })
    .catch((err) => console.log(err))
}

export const setCookie = (key, value) => {
  if (process.browser) {
    cookie.set(key, value, {
      expires: 7,
    })
  }
}

export const removeCookie = (key) => {
  if (process.browser) {
    cookie.remove(key)
  }
}

export const getCookie = (key) => {
  if (process.browser) {
    return cookie.get(key)
  }
}

export const deleteCookie = (name) => {
  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
}

export const setLocalStorage = (key, value) => {
  if (process.browser) {
    if(key === KEY_LOCAL_STORAGE.USER){
      let company = _.get(value, 'group.ancestors[0]', value.group)
      posthog.identify(value._id, {
        email: value.email,
        name: `${value.name} ${value.lastname}`,
        company: company.name ?? company.businessName,
        roles: value.roles,
      });
    }
    localStorage.setItem(key, JSON.stringify(value))
  }
}

export const getLocalStorage = (key, parse = false) => {
  if (process.browser) {
    const value = localStorage.getItem(key)
    return parse ? JSON.parse(value) : value
  }
}

export const removeLocalStorage = (key) => {
  if (process.browser) {
    localStorage.removeItem(key)
  }
}

export const authenticate = (data, next) => {
  if(!data) return next()
  if(data.token){
    setCookie("token", data.token)
  }
  if(data.user){
    setLocalStorage(KEY_LOCAL_STORAGE.USER, data.user)
  }
  next()
}

export const isAuth = () => {
  if (typeof window !== "undefined") {
    const cookieChecked = getCookie("token")
    if (cookieChecked) {
      if (
        localStorage.getItem(KEY_LOCAL_STORAGE.USER) &&
        localStorage.getItem(KEY_LOCAL_STORAGE.USER) != undefined
      ) {
        if (
          localStorage.getItem(KEY_LOCAL_STORAGE.USER) == "undefined" ||
          !JSON.parse(localStorage.getItem(KEY_LOCAL_STORAGE.USER)) ||
          !JSON.parse(localStorage.getItem(KEY_LOCAL_STORAGE.USER)).group.nav
        ) {
          return signout(() => { })
        }
        return JSON.parse(localStorage.getItem(KEY_LOCAL_STORAGE.USER))
      } else {
        return false
      }
    }
  }
}

export const modifyUser = (field, value) => {
  if (typeof browser) {
    if (
      localStorage.getItem(KEY_LOCAL_STORAGE.USER) &&
      localStorage.getItem(KEY_LOCAL_STORAGE.USER) != undefined
    ) {
      let user_mod = JSON.parse(localStorage.getItem(KEY_LOCAL_STORAGE.USER))

      user_mod[field] = value
      localStorage.setItem(KEY_LOCAL_STORAGE.USER, JSON.stringify(user_mod))
    }
  }
}

export const getCompany = () => {
  if (process.browser) {
    const cookieChecked = getCookie("token")
    if (cookieChecked) {
      if (
        localStorage.getItem(KEY_LOCAL_STORAGE.USER) &&
        localStorage.getItem(KEY_LOCAL_STORAGE.USER) != undefined
      ) {
        let user = JSON.parse(localStorage.getItem(KEY_LOCAL_STORAGE.USER))
        if (user.group.ancestors.length > 0) {
          return user.group.ancestors[0]
        } else {
          return user.group
        }
      } else {
        return false
      }
    }
  }
}

export const changeCompanyValue = (key, value) => {
  const authValue = isAuth();

  const recursiveFn = (group) => {
    if (group.ancestors.length > 0) return recursiveFn(group.ancestors[0]);
    group[key] = value;
  }

  recursiveFn(authValue.group);
  setLocalStorage(KEY_LOCAL_STORAGE.USER, authValue);
}

export const reloadtoken = (token) => {
  return fetch(`${API_NEXT}/profile`, {
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + (token ? token : getCookie("token")),
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else if (response.status === 401) {
        deleteSession()
        return typeof window !== 'undefined' && window.location.replace("/login")
      } else {
        throw new Error("Network response was not ok.")
      }
    })
    .catch((err) => {
      console.error(err)
    })
}

export const forgotpassword = (email) => {
  return fetch(`${API}/forgot-password`, {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify({ email }),
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => console.log(err))
}

export const resetpassword = (resetPasswordLink, newPassword) => {
  return fetch(`${API}/reset-password`, {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify({ resetPasswordLink, newPassword }),
  })
    .then((response) => response.json())
    .catch((err) => console.log(err))
}

export const isGhostAdmin = () => {
  if (process.browser) {
    const cookieChecked = getCookie("admin_token")
    return !!cookieChecked
  }
}

export const changeToGhostAdmin = (next) => {
  if (process.browser) {
    let adminToken = getCookie("admin_token")
    removeCookie("admin_token")

    const queryClient = new QueryClient()
    queryClient.clear()
    reloadtoken(adminToken).then((data) => {
      if (data.error) return ""
      authenticate(data, next)
    })
  }
}

export const getVarUrl = (url, user) => {
  let group = user.group
  if (group && group.parent) {
    group = group.ancestors[0]
  } else if (!group) {
    return url
      .replace("%name%", user.name)
      .replace("%lastname%", user.lastname)
      .replace("%email%", user.email)
  }
  return url
    .replace("%name%", user.name)
    .replace("%lastname%", user.lastname)
    .replace("%email%", user.email)
    .replace("%group%", user.group.name)
    .replace("%company%", group.name)
}

export const getVarUrlEncode = (url, user) => {
  let group = user.group
  if (group && group.parent) {
    group = group.ancestors[0]
  } else if (!group) {
    return url
      .replace("%name%", encodeURIComponent(user.name))
      .replace("%lastname%", encodeURIComponent(user.lastname))
      .replace("%email%", encodeURIComponent(user.email))
  }
  return url
    .replace("%name%", encodeURIComponent(user.name))
    .replace("%lastname%", encodeURIComponent(user.lastname))
    .replace("%email%", encodeURIComponent(user.email))
    .replace("%group%", encodeURIComponent(user.group.name))
    .replace("%company%", encodeURIComponent(group.name))
}

export const setChaportId = (id) => {
  return fetch(`${API}/chaport`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify({ id }),
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => console.log(err))
}   