'use client';
import { getCookie } from "@/actions/auth";
import { SocketMessage, SocketStatus } from "@/components/crm/socket/SocketContext";
import { Howl } from 'howler';
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

interface UseAwsSocketProps {
    onReceiveEvent?: (message: SocketMessage, socket: WebSocket) => void;
    onStatusChange?: (status: SocketStatus) => void;
}

export default function useAwsSocket({ onReceiveEvent, onStatusChange }: UseAwsSocketProps = {}) {
    const [status, setStatus] = useState<SocketStatus>('online');
    const socketRef = useRef<WebSocket | null>(null);
    const isOnline = useRef<boolean>(((typeof navigator !== 'undefined') ? !!navigator?.onLine : false));
    const [retryCount, setRetryCount] = useState<number>(0);
    const [reconnectTimeout, setReconnectTimeout] = useState<NodeJS.Timeout | null>(null);

    const sessionToken = getCookie('token');
    const websocketUrl = useMemo(() => `${process.env.WEBSOCKET_URL}/?token=${sessionToken}`, [sessionToken]);

    const onChangeStatus = (newStatus: SocketStatus) => {
        setStatus(newStatus);
        onStatusChange?.(newStatus);
    };

    const clearReconnectTimeout = useCallback(() => {
        if (reconnectTimeout) {
            clearTimeout(reconnectTimeout);
            setReconnectTimeout(null);
        }
    }, [reconnectTimeout]);

    const connectSocket = () => {
        if (!sessionToken || socketRef.current || !isOnline.current) return;

        socketRef.current = new WebSocket(websocketUrl);

        socketRef.current.onopen = () => {
            console.log('Connected to WebSocket');
            onChangeStatus('online');
            setRetryCount(0);
            clearReconnectTimeout();
        };

        socketRef.current.onmessage = (message: MessageEvent<string>) => {
            const data: SocketMessage = JSON.parse(message.data) as SocketMessage;
            onReceiveEvent?.(data, socketRef.current as WebSocket);
        };

        socketRef.current.onclose = () => {
            console.warn('Disconnected from WebSocket');
            onChangeStatus('offline');
            attemptReconnect();
        };

        socketRef.current.onerror = (error: Event) => {
            console.error('WebSocket error:', error);
            onChangeStatus('error');
            attemptReconnect();
        };
    };

    const attemptReconnect = useCallback(() => {
        socketRef.current?.close();
        socketRef.current = null;

        if (retryCount >= 10) {
            console.warn("Max reconnect attempts reached.");
            return;
        }

        const delay = Math.min(1000 * (2 ** retryCount), 30000);
        console.log(`Reconnecting in ${delay / 1000}s...`);

        setReconnectTimeout(setTimeout(() => {
            setRetryCount(prev => prev + 1);
            connectSocket();
        }, delay));
    }, [retryCount, connectSocket]);

    useEffect(() => {
        if (!sessionToken) return;
        connectSocket();

        const handleOnline = () => {
            console.log("Internet is back! Reconnecting...");
            isOnline.current = true;
            setStatus('online');
            connectSocket();
        };

        const handleOffline = () => {
            console.warn("Internet connection lost.");
            isOnline.current = false;
            socketRef.current?.close();
            socketRef.current = null;
            setStatus('offline');
        };

        window.addEventListener("online", handleOnline);
        window.addEventListener("offline", handleOffline);

        return () => {
            socketRef.current?.close();
            socketRef.current = null;

            window.removeEventListener("online", handleOnline);
            window.removeEventListener("offline", handleOffline);
        };
    }, []);

    const sendMessage = (message: any) => {
        if (!socketRef.current || socketRef.current.readyState !== WebSocket.OPEN) return;
        try {
            socketRef.current.send(JSON.stringify(message));
        } catch (error) {
            console.error(error);
        }
    }

    const joinRoom = (room: string) => sendMessage({ action: 'joinRoom', room, token: sessionToken });
    const leaveRoom = (room: string) => sendMessage({ action: 'leaveRoom', room })

    return {
        socket: socketRef.current,
        leaveRoom,
        joinRoom,
        status
    };
}

export const NOTIFICATION_SOUNDS = {
    'default': '/assets/audios/default-notification-sound.mp3',
    'serenidad': '/assets/audios/serenidad-notification-sound.mp3'
}

export const playNotificationSound = (
    sound: "default" | "serenidad" | "none",
    options?: { volume?: number }
) => {
    if (sound === "none") return;

    const soundUrl = NOTIFICATION_SOUNDS[sound] || NOTIFICATION_SOUNDS.default;
    const volume = typeof options?.volume == 'number' ? options?.volume : 1;

    try {
        const soundInstance = new Howl({ src: [soundUrl], volume });
        soundInstance.play();
    } catch {
        const soundInstance = new Audio(soundUrl);
        soundInstance.volume = volume;
        soundInstance.play();
    }
};
