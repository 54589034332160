"use client"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { motion, useAnimation } from "framer-motion"
import { Bell } from "lucide-react"
import { type ReactNode, useEffect, useState } from "react"

export default function ToastNotificationCard({
    icon,
    duration,
    title,
    body,
}: {
    icon?: ReactNode
    title?: string
    body?: string
    duration: number
}) {
    const [progress, setProgress] = useState(100)
    const [isPaused, setIsPaused] = useState(false)
    const controls = useAnimation()

    useEffect(() => {
        let interval: NodeJS.Timeout

        if (!isPaused) {
            interval = setInterval(() => {
                setProgress((prevProgress) => {
                    if (prevProgress <= 0) {
                        clearInterval(interval)
                        return 0
                    }
                    return prevProgress - 100 / (duration / 100)
                })
            }, 100)
        }

        return () => {
            if (interval) clearInterval(interval)
        }
    }, [duration, isPaused])

    useEffect(() => {
        controls.start({ width: `${progress}%` })
    }, [progress, controls])

    return (
        <Card
            className="border-none p-4 w-full relative overflow-hidden"
            onMouseEnter={() => setIsPaused(true)}
            onMouseLeave={() => setIsPaused(false)}
        >
            <CardHeader className="p-0 pb-2">
                <CardTitle className="text-sm flex gap-x-2 items-center font-medium">
                    {icon || <Bell className="inline-block h-5 w-5" />}
                    {title}
                </CardTitle>
            </CardHeader>
            <CardContent className="p-0">
                <p className="text-sm text-muted-foreground">{body}</p>
                <motion.div
                    className="absolute bottom-0 left-0 h-1 bg-primary"
                    initial={{ width: "100%" }}
                    animate={controls}
                    transition={{ duration: 0.1, ease: "linear" }}
                />
            </CardContent>
        </Card>
    )
}
