import { getChannelTemplate } from "@/actions/channels/templates";
import dayjs from "dayjs";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";

export const getMedium = (action) => {
  const mediumMap = {
    facebook: "fb",
    instagram: "ig",
    whatsapp: "wpp",
  };

  return mediumMap[action] || action;
};

export const getUsesGracePeriod = (action: string) => {
  const gracePeriodActions = new Set(["fb", "ig", "wpp", 'whatsapp_business', 'whatsapp_360_dialog']);
  return gracePeriodActions.has(action);
};

export const useChannels = (activeChannel, prospect) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [templates, setTemplates] = useState<any[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<any>();

  const gracePeriodOpen = useMemo(() => {
    if (prospect == null || !activeChannel) return false;
    if (!getUsesGracePeriod(activeChannel.type)) return true;
    let medium = activeChannel.type;

    if (['whatsapp_360_dialog', 'whatsapp_business'].includes(medium)) {
      medium = 'wpp'
    }

    let diffHours: number = ['ig', 'fb'].includes(medium)
      ? 24 * 7
      : 24;

    const lastDayInteractions = prospect.interactions.filter((i) =>
      i.type == "channelMessage" &&
      i.content.medium == medium &&
      i.content.origin == "contact" &&
      moment().diff(moment(i.createdAt), "hours") < diffHours
    );

    return lastDayInteractions.length > 0;
  }, [prospect, prospect?.interactions, activeChannel]);

  const getChannelTemplates = async () => {
    if (!activeChannel) return;
    const { type, config, id, _id: channelId } = activeChannel || {};
    const { pageId } = config || {}

    const mediumInteractions = prospect.interactions.findLast((i) =>
      i.type == "channelMessage"
      && (i.content.medium == type || i.content.medium == 'wpp' && ['whatsapp_business', 'whatsapp_360_dialog'].includes(type))
    );

    if (gracePeriodOpen || !mediumInteractions) return null;

    setIsLoading(true)

    try {
      const { filteredTemplates = [], placeholderValues = [] } = await getChannelTemplate(
        id || channelId,
        prospect._id,
        pageId,
        type
      );

      const processedTemplates = filteredTemplates.map(
        (template, i) => ({
          ...template,
          placeholderValues: placeholderValues[i],
        })
      );

      setTemplates(processedTemplates);
    } catch {
      console.error('** ERROR AL OBTENER LAS PLANTILLAS DEL CANAL **')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!prospect || !activeChannel || ['ig', 'fb'].includes(activeChannel?.type)) {
      if (activeChannel) setTemplates([]);
      return;
    };

    getChannelTemplates();
  }, [activeChannel])

  const getTag = () => {
    if (!activeChannel || !['ig', 'fb'].includes(activeChannel.type)) return;
    let medium = activeChannel.type;

    const lastContactInteraction = prospect.interactions.find((interaction) => {
      return interaction.type == "channelMessage" &&
        interaction.content.medium == medium &&
        interaction.content.origin == 'contact'
    });

    if (!lastContactInteraction) return undefined;
    const createdAt = lastContactInteraction.createdAt;
    const diff = dayjs().diff(dayjs(createdAt), "hours")

    if (diff > 24 && diff < (24 * 7)) return 'human_agent';
  }

  const tag = useMemo(() => getTag(), [activeChannel, prospect])

  return {
    selectedTemplate,
    setSelectedTemplate,
    gracePeriodOpen,
    templates,
    isLoading,
    getTag,
    tag
  };
};
